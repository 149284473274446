<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> レポート - 競合</h1>
    </header>

    <!-- 検索欄 -->
    <inline-loader v-if="loading_count > 0"></inline-loader>
    <template v-else>
        <section class="section">
            <div class="row align-items-end">
                <div class="col-6">
                    <label class="form-label">事業部</label>
                    <form-select
                        v-model="condition.department_id"
                        :options="options_department"
                    ></form-select>
                </div>
                <div class="col-14">
                    <label class="form-label">規格</label>
                    <form-select-search-multiple
                        v-model="condition.standards"
                        :options="Standard.options()"
                        placeholder="全て"
                    ></form-select-search-multiple>
                </div>
                <div class="col-10">
                    <label class="form-label">期間 (初回訪問日)</label>
                    <div class="input-group">
                        <form-input-date
                            v-model="condition.date_from"
                        ></form-input-date>
                        <span class="input-group-text">〜</span>
                        <form-input-date
                            v-model="condition.date_to"
                        ></form-input-date>
                    </div>
                </div>
                <div class="col-6">
                    <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
                </div>
            </div>
        </section>

        <section class="section">
            <!-- 表出力 -->
            <section class="section">
                <table v-if="stats !== null" class="table table-bordered table-hover">
                    <colgroup>
                        <col class="col-7">
                        <col class="col-3">
                        <col class="col-3">
                        <col class="col-3">
                        <col class="col-3">
                        <col class="col-3">
                        <col class="col-7">
                        <col class="col-7">
                    </colgroup>
                    <thead>
                        <tr class="table-dark">
                            <th class="text-center">競合</th>
                            <th class="text-center">競合件数</th>
                            <th class="text-center">勝</th>
                            <th class="text-center">負</th>
                            <th class="text-center">他社</th>
                            <th class="text-center">未決着</th>
                            <th class="text-center">勝ち理由</th>
                            <th class="text-center">負け理由</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="list in stats.list" :key="list.competitor_label">
                            <td class="align-middle table-light">{{ list.competitor_label }}</td>
                            <td class="align-middle text-end">
                                <!-- 競合件数 -->
                                <button 
                                    v-if="list.competitors.total.count > 0"
                                    @click="openProjectListModal(
                                        list.competitor_label, 
                                        '競合件数', 
                                        list.competitors.total.project_ids
                                    )" 
                                    type="button" 
                                    class="btn btn-link p-0" 
                                    data-bs-toggle="modal" 
                                    :data-bs-target="`#projectListModal`"
                                >{{ list.competitors.total.count }}</button>
                                <span v-else>{{ list.competitors.total.count }}</span>
                            </td>
                            <td class="align-middle text-end">
                                <!-- 勝 -->
                                <button 
                                    v-if="list.competitors.ordered.count > 0"
                                    @click="openProjectListModal(
                                        list.competitor_label, 
                                        '勝', 
                                        list.competitors.ordered.project_ids
                                    )" 
                                    type="button" 
                                    class="btn btn-link p-0" 
                                    data-bs-toggle="modal" 
                                    :data-bs-target="`#projectListModal`"
                                >{{ list.competitors.ordered.count }}</button>
                                <span v-else>{{ list.competitors.ordered.count }}</span>
                            </td>
                            <td class="align-middle text-end">
                                <!-- 負 -->
                                <button 
                                    v-if="list.competitors.lost.count > 0"
                                    @click="openProjectListModal(
                                        list.competitor_label, 
                                        '負',
                                        list.competitors.lost.project_ids
                                    )" 
                                    type="button" 
                                    class="btn btn-link p-0" 
                                    data-bs-toggle="modal" 
                                    :data-bs-target="`#projectListModal`"
                                >{{ list.competitors.lost.count }}</button>
                                <span v-else>{{ list.competitors.lost.count }}</span>
                            </td>
                            <td class="align-middle text-end">
                                <!-- 他社 -->
                                <button 
                                    v-if="list.competitors.other_company.count > 0"
                                    @click="openProjectListModal(
                                        list.competitor_label, 
                                        '他社',
                                        list.competitors.other_company.project_ids
                                    )" 
                                    type="button" 
                                    class="btn btn-link p-0" 
                                    data-bs-toggle="modal" 
                                    :data-bs-target="`#projectListModal`"
                                >{{ list.competitors.other_company.count }}</button>
                                <span v-else>{{ list.competitors.other_company.count }}</span>
                            </td>
                            <td class="align-middle text-end">
                                <!-- 未決着 -->
                                <button 
                                    v-if="list.competitors.undecided.count > 0"
                                    @click="openProjectListModal(
                                        list.competitor_label, 
                                        '未決着',
                                        list.competitors.undecided.project_ids
                                    )" 
                                    type="button" 
                                    class="btn btn-link p-0" 
                                    data-bs-toggle="modal" 
                                    :data-bs-target="`#projectListModal`"
                                >{{ list.competitors.undecided.count }}</button>
                                <span v-else>{{ list.competitors.undecided.count }}</span>
                            </td>
                            <td class="align-middle">
                                <template v-for="(ordered_reason, index) in list.competitors.ordered_reasons" :key="index">
                                    <span class="d-block">
                                        {{ index }}(
                                            <!-- 勝ち理由 -->
                                            <button 
                                                v-if="ordered_reason.count > 0"
                                                @click="openProjectListModal(
                                                    list.competitor_label, 
                                                    '勝ち理由',
                                                    ordered_reason.project_ids
                                                )" 
                                                type="button" 
                                                class="btn btn-link p-0" 
                                                data-bs-toggle="modal" 
                                                :data-bs-target="`#projectListModal`"
                                            >{{ ordered_reason.count }}</button>
                                            <span v-else>{{ ordered_reason.count }}</span>
                                        )
                                    </span>
                                </template>
                            </td>
                            <td class="align-middle">
                                <template v-for="(lost_reason, index) in list.competitors.lost_reasons" :key="index">
                                    <span class="d-block">
                                        {{ index }}(
                                            <!-- 負け理由 -->
                                            <button 
                                                v-if="lost_reason.count > 0"
                                                @click="openProjectListModal(
                                                    list.competitor_label, 
                                                    '負け理由',
                                                    lost_reason.project_ids
                                                )" 
                                                type="button" 
                                                class="btn btn-link p-0" 
                                                data-bs-toggle="modal" 
                                                :data-bs-target="`#projectListModal`"
                                            >{{ lost_reason.count }}</button>
                                            <span v-else>{{ lost_reason.count }}</span>
                                        )
                                    </span>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="border-top-0">
                        <tr class="table-primary">
                            <td class="align-middle table-secondary">{{ stats.total.competitor_label }}</td>
                            <td class="align-middle text-end table-secondary">
                                <!-- 競合件数 合計 -->
                                <button 
                                    v-if="stats.total.competitors.total.count > 0"
                                    @click="openProjectListModal(
                                        stats.total.competitor_label, 
                                        '競合件数',
                                        stats.total.competitors.total.project_ids
                                    )" 
                                    type="button" 
                                    class="btn btn-link p-0" 
                                    data-bs-toggle="modal" 
                                    :data-bs-target="`#projectListModal`"
                                >{{ stats.total.competitors.total.count }}</button>
                                <span v-else>{{ stats.total.competitors.total.count }}</span>
                            </td>
                            <td class="align-middle text-end table-secondary">
                                <!-- 勝 合計 -->
                                <button 
                                    v-if="stats.total.competitors.ordered.count > 0"
                                    @click="openProjectListModal(
                                        stats.total.competitor_label, 
                                        '勝',
                                        stats.total.competitors.ordered.project_ids
                                    )" 
                                    type="button" 
                                    class="btn btn-link p-0" 
                                    data-bs-toggle="modal" 
                                    :data-bs-target="`#projectListModal`"
                                >{{ stats.total.competitors.ordered.count }}</button>
                                <span v-else>{{ stats.total.competitors.ordered.count }}</span>
                            </td>
                            <td class="align-middle text-end table-secondary">
                                <!-- 負 合計 -->
                                <button 
                                    v-if="stats.total.competitors.lost.count > 0"
                                    @click="openProjectListModal(
                                        stats.total.competitor_label, 
                                        '負',
                                        stats.total.competitors.lost.project_ids
                                    )" 
                                    type="button" 
                                    class="btn btn-link p-0" 
                                    data-bs-toggle="modal" 
                                    :data-bs-target="`#projectListModal`"
                                >{{ stats.total.competitors.lost.count }}</button>
                                <span v-else>{{ stats.total.competitors.lost.count }}</span>
                            </td>
                            <td class="align-middle text-end table-secondary">
                                <!-- 他社 合計 -->
                                <button 
                                    v-if="stats.total.competitors.other_company.count > 0"
                                    @click="openProjectListModal(
                                        stats.total.competitor_label, 
                                        '他社',
                                        stats.total.competitors.other_company.project_ids
                                    )" 
                                    type="button" 
                                    class="btn btn-link p-0" 
                                    data-bs-toggle="modal" 
                                    :data-bs-target="`#projectListModal`"
                                >{{ stats.total.competitors.other_company.count }}</button>
                                <span v-else>{{ stats.total.competitors.other_company.count }}</span>
                            </td>
                            <td class="align-middle text-end table-secondary">
                                <!-- 未決着 合計 -->
                                <button 
                                    v-if="stats.total.competitors.undecided.count > 0"
                                    @click="openProjectListModal(
                                        stats.total.competitor_label, 
                                        '未決着',
                                        stats.total.competitors.undecided.project_ids
                                    )" 
                                    type="button" 
                                    class="btn btn-link p-0" 
                                    data-bs-toggle="modal" 
                                    :data-bs-target="`#projectListModal`"
                                >{{ stats.total.competitors.undecided.count }}</button>
                                <span v-else>{{ stats.total.competitors.undecided.count }}</span>
                            </td>
                            <td class="align-middle table-secondary">
                                <template v-for="(ordered_reason, index) in stats.total.competitors.ordered_reasons" :key="index">
                                    <span class="d-block">
                                        {{ index }}(
                                            <!-- 勝ち理由 合計 -->
                                            <button 
                                                v-if="ordered_reason.count > 0"
                                                @click="openProjectListModal(
                                                    stats.total.competitor_label, 
                                                    '勝ち理由',
                                                    ordered_reason.project_ids
                                                )" 
                                                type="button" 
                                                class="btn btn-link p-0" 
                                                data-bs-toggle="modal" 
                                                :data-bs-target="`#projectListModal`"
                                            >{{ ordered_reason.count }}</button>
                                            <span v-else>{{ ordered_reason.count }}</span>
                                        )
                                    </span>
                                </template>
                            </td>
                            <td class="align-middle table-secondary">
                                <template v-for="(lost_reason, index) in stats.total.competitors.lost_reasons" :key="index">
                                    <span class="d-block">
                                        {{ index }}(
                                            <!-- 負け理由 合計 -->
                                            <button 
                                                v-if="lost_reason.count > 0"
                                                @click="openProjectListModal(
                                                    stats.total.competitor_label, 
                                                    '負け理由',
                                                    lost_reason.project_ids
                                                )" 
                                                type="button" 
                                                class="btn btn-link p-0" 
                                                data-bs-toggle="modal" 
                                                :data-bs-target="`#projectListModal`"
                                            >{{ lost_reason.count }}</button>
                                            <span v-else>{{ lost_reason.count }}</span>
                                        )
                                    </span>
                                </template>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <div v-else>
                    <p>該当 : 0件</p>
                </div>
            </section>
        </section>


        <!-- 各数値クリック時OPENモーダル -->
        <!--
            bootstrapのモーダル機能を利用している
            showクラスが追加されると開く
            class="modal fade show"
        -->
        <div class="modal fade" :id="`projectListModal`" tabindex="-1" :aria-labelledby="`projectListModal`" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" :id="`activityModalLabel`">案件一覧 - {{ modal_title }}</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body mb-5">
                        <div v-if="modal_projects_list.length">
                            <table class="table table-sm">
                                <thead>
                                    <tr class="table-dark table-head">
                                        <th></th>
                                        <th class="text-center w-ms">会社名</th>
                                        <th class="text-center w-ss">客先担当者</th>
                                        <th class="text-center w-ms">事業部</th>
                                        <th class="text-center w-ss">追客担当</th>
                                        <th class="text-center w-ss">ステータス</th>
                                        <th class="text-center w-ss">評価</th>
                                        <th class="text-center w-mm">規格</th>
                                        <th class="text-center">その他規格・備考</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="project in modal_projects_list" :key="project.project_id">
                                        <td @click.stop>
                                            <router-link :to="{name: 'ProjectEdit', params: {id: project.project_id}}" class="btn btn-link" target="_blank"><i class="bi bi-box-arrow-up-right"></i></router-link>
                                        </td>
                                        <td class="align-middle text-start px-3">{{ project.customer.company_name }}</td>
                                        <td class="align-middle text-start px-3">{{ project.primary_contact ? project.primary_contact.contact_name : '' }}</td>
                                        <td>{{ project.department ? project.department.department_name : '' }}</td>
                                        <td>{{ project.follow_user ? project.follow_user.user_name : '' }}</td>
                                        <td>{{ project.status_label }}</td>
                                        <td>{{ project.rank_label }}</td>
                                        <td>{{ project.standards_label }}</td>
                                        <td class="fs-7">{{ project.standard_note }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else>
                            <p class="alert alert-secondary">{{ modal_loading ? '検索中です' : '※案件が存在しません' }}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary mb-0" data-bs-dismiss="modal">閉じる</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
import Department from '@/models/entities/department';
import Project from '@/models/entities/project';
import Standard from '@/models/enums/standard';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormSelect from '@/components/forms/FormSelect';
import FormInputDate from '@/components/forms/FormInputDate';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';

export default {
    name: 'ReportCompetitor',
    components: {
        InlineLoader,
        FormSelect,
        FormInputDate,
        FormSelectSearchMultiple,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,
            modal_loading: false,

            // 検索フォーム
            options_department: [],

            // enum options
            Standard,

            // 検索条件
            condition: {
                department_id: null,
                standards: null,
                date_from: null,
                date_to: null,
            },

            // 表示データ
            stats: null,

            // モーダル
            modal_title: null,
            modal_projects_list: [],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.startScreenLoading();
            this.setInitialDate();
            this.fetchDepartment();
        },
        // 検索期間の初期値設定
        setInitialDate () {
            let date_obj = new Date();
            let year = date_obj.getFullYear().toString();

            this.condition.date_from = year + '-01-01';
            this.condition.date_to = year + '-12-31';
        },
        fetchDepartment() {
            this.loading_count++;

            // 事業部マスタ取得
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                //事業部未登録の場合
                if (this.options_department.length === 0) {
                    this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                }

                // 事業部初期値セット
                if (this.$store.state.auth.department_id) {
                    this.condition.department_id = this.$store.state.auth.department_id;
                } else {
                    this.condition.department_id = this.options_department[0].value;
                }

                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
                this.loading_count--;
            })
        },
        // 検索
        search() {
            // 競合レポート取得
            this.fetchCompetitorReport();
        },
        // 競合レポート取得
        fetchCompetitorReport() {
            this.loading_count++;

            // 表示データ初期化
            this.stats = null;

            this.$http.get('/report/competitor', {
                params: this.condition,
            })
            .then(response => {
                this.stats = ('list' in response.data) ? response.data : null;
            })
            .finally(() => {
                // ローディング終了
                this.loading_count--;
            })
        },

        //指定数値の案件一覧モーダルOPEN
        openProjectListModal(competitor_label, th_column, project_ids) {
            this.modal_loading = true;
            // モーダルの案件一覧をリセット
            this.modal_projects_list.splice(0);

            // モーダルタイトル生成
            let title_row = [competitor_label, th_column];
            this.modal_title = title_row.join(" | ");
            this.startScreenLoading();

            // 検索条件
            let project_condition = {};
            //[共通] 事業部
            project_condition.project_ids = project_ids;

            //案件一覧取得
            this.$http.get('/project/ids', {
                params: project_condition,
            })
            .then((response) => {
                for (let row of response.data) {
                    this.modal_projects_list.push(new Project(row));
                }
            })
            .finally(() => {
                this.modal_loading = false;
                this.endScreenLoading();
            })
        },
    }
}
</script>

<style scoped>
    .w-mm {
        width: 16%;
    }
    .w-ms {
        width: 13%;
    }
    .w-ss {
        width: 10%;
    }
    .fs-7 {font-size: .85rem;}
</style>
